import { formatDate } from "@/helpers/dateProcessor";
import { ITableWrapperColumns } from "@/types";
import CountyCodeColumn from "@/views/admin/quoteCountyValidations/CountyCodeColumn.vue";
const displayArrData = <T extends string | number>(arg: T[]): string => {
  const filteredValues = arg.filter(val => Boolean(val)).join(",");
  return filteredValues || "N/A";
};
export const tableColumnsListCountyValidation: ITableWrapperColumns = {
  exemptedRoles: {
    formatDataKey: "Exempted roles",
    formatDataValue: displayArrData,
    allowInAdvancedFilter: true,
    allowInTableOptions: true,
    visibleByDefault: true,
    allowSort: true,
    position: 2
  },
  title: {
    formatDataKey: "Validation Title",
    formatDataValue: (arg: any) => arg || "N/A",
    allowInAdvancedFilter: true,
    allowInTableOptions: true,
    visibleByDefault: true,
    allowSort: true,
    position: 2,
    colWidth: 220
  },
  companyNumbers: {
    formatDataKey: "Company Numbers",
    formatDataValue: displayArrData,
    allowInAdvancedFilter: true,
    allowInTableOptions: true,
    visibleByDefault: true,
    allowSort: true,
    position: 3,
    colWidth: 200
  },
  effectiveDate: {
    formatDataKey: "Effective Date",
    formatDataValue: (date: string) => formatDate(date),
    allowInAdvancedFilter: true,
    allowInTableOptions: true,
    visibleByDefault: true,
    allowSort: true,
    position: 4,
    colWidth: 200
  },
  county: {
    formatDataKey: "County",
    formatDataValue: (arg: any) => displayArrData(arg),
    formatDataComponent: CountyCodeColumn,
    allowInAdvancedFilter: true,
    allowInTableOptions: true,
    visibleByDefault: true,
    allowSort: true,
    allowInSimpleFilter: true,
    position: 5,
    colWidth: 170
  },
  city: {
    formatDataKey: "City",
    formatDataValue: (arg: any) => arg || "N/A",
    allowInAdvancedFilter: true,
    allowInTableOptions: true,
    visibleByDefault: true,
    allowSort: true,
    allowInSimpleFilter: true,
    position: 7,
    colWidth: 170
  },
  state: {
    formatDataKey: "State",
    formatDataValue: (arg: any) => arg || "N/A",
    allowInAdvancedFilter: true,
    allowInTableOptions: true,
    visibleByDefault: true,
    allowSort: true,
    allowInSimpleFilter: true,
    position: 8,
    colWidth: 120
  },
  zipCode: {
    formatDataKey: "Zip Codes",
    formatDataValue: displayArrData,
    allowInAdvancedFilter: true,
    allowInTableOptions: true,
    visibleByDefault: true,
    allowSort: true,
    allowInSimpleFilter: true,
    position: 6,
    colWidth: 180
  },
  isActive: {
    formatDataKey: "Active",
    formatDataValue: (arg: boolean) => (arg === true ? "Yes" : "No "),
    allowInAdvancedFilter: true,
    allowInTableOptions: true,
    visibleByDefault: true,
    allowSort: true,
    allowInSimpleFilter: true,
    position: 1,
    colWidth: 140
  }
};
