
import Vue from "vue";

export default Vue.extend({
  name: "CountyCodeColumn",
  props: {
    row: {
      type: Object,
      required: false,
      default: () => ({ county: [] })
    }
  },
  computed: {
    formattedCountyCode(): string {
      if (!this.row?.county?.length) return "N/A";
      return this.showToolTip
        ? `${this.row.county.slice(0, 5).join(", ")}...`
        : this.row.county.join(", ");
    },
    showToolTip(): boolean {
      return this.row.county.length > 5;
    },
    allCountyCodes(): string {
      const countyCodes = this.row.county;

      if (!Array.isArray(countyCodes)) return countyCodes.toString();
      if (!countyCodes.length) return "N/A";
      if (countyCodes.length > 5) {
        return countyCodes
          .reduce((result, code, index) => {
            if (index % 3 === 0) {
              result.push(countyCodes.slice(index, index + 3).join(", "));
            }
            return result;
          }, [])
          .join("<br />");
      }

      return countyCodes.join(", ");
    }
  }
});
